import {
  createEntityAdapter,
} from "@reduxjs/toolkit";
import createFilterSlice from "../utils/filterSlice";
import { Location } from "../locations";
import { Product } from "../products";
import { RootState } from "../store";
import { CoffeeMenu } from "../coffee-menus";
import { UserGroup } from "../user-groups";

export interface CoffeeMachine {
  id: string;
  serial: string;
  model: string;
  xml: string;
  articleNumber: string;
  preselectMode: PreselectMode;
  picture: string;
  currency: string;
  name: string;
  createdByUserId: string;
  location: Location;
  coffeeMenu: CoffeeMenu;
  userGroups?: UserGroup[];
  products?: Product[];
  paymentId: string;
  frogVersion?: string;
  nameHuman?: string;
  pin: string;
  maintenancePin: string;
}

export interface EditMachineArgs {
  id: string;
  locationId: string;
  coffeeMenuId?: string | null;
  coffeeMenus?: Record<string, string | null>; // Map from user-group-id to coffee menu id
  name?: string;
}

export enum PreselectMode {
  Open = "open",
  Prepaid = "prepaid",
  AuthorizedAccess = "authorized_access",
  Payment = "payment",
  Custom = "custom"
}

export const coffeeMachinesAdapter = createEntityAdapter<CoffeeMachine>({
  selectId: (machine) => machine.id,
  sortComparer: (a, b) => a.serial.localeCompare(b.serial),
});

const slice = createFilterSlice({
  name: "coffee-machines",
  initialState: { filter: {locations: [], machines: []} },
  reducers: {
  },
})

export default slice.reducer;
export const selectFilter = (state: RootState) =>
  state.coffeeMachines.filter ?? {locations: [], machines: []};
export const { reset, setFilter } = slice.actions;
