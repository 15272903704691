import {
  Grid,
  Stack,
  Typography,
  styled,
  Box,
  CircularProgress,
  Checkbox,
  Button,
  Link,
  Tooltip,
  capitalize,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useEditCoffeeMenuProductMutation,
  useGetCoffeeMenuProductsQuery,
  useGetCoffeeMenuQuery,
  useSaveProductOrderMutation,
} from "../../../state/services/api";
import {
  Product,
  productName,
  selectProductIds,
  setProductIds,
} from "../../../state/products";
import Tile from "../../../components/Tile";
import TileGrid from "../../../components/TileGrid";
import { AddCircle, ChevronLeft, Save } from "@mui/icons-material";
import { useEntityDialog } from "../../../hooks/dialog";

import { TemporaryAlert } from "../../../components/Alert";
import CreateProductDialog, {
  CREATE_PRODUCT_DIALOG_NAME,
} from "./product/CreateDialog";
import EditProductDialog, {
  EDIT_PRODUCT_DIALOG_NAME,
} from "./product/EditDialog";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Mode } from "../../../state/coffee-menus";
import { formatMoney } from "../../../utils/money";
import { ellipsisOverflow } from "../../../utils/string";

interface MatchParams {
  menuId: string;
}

const ProductTile = styled(Tile)`
  &:hover .MuiPaper-root {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

const ProductImage = styled("img")`
  width: 100%;
  height: 200px;
  object-fit: contain;
  mix-blend-mode: multiply;
  pointer-events: none;
`;

const ProductListing = () => {
  const { t } = useTranslation();
  const { t: mt } = useTranslation("machine");

  const { menuId } = useParams<MatchParams>();

  const [isEditProducts, setEditProducts] = useState(false);

  const handleEditProducts = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setEditProducts(true);
    },
    [setEditProducts]
  );

  const closeEditMode = () => {
    setEditProducts(false);
  };

  const productIds = useSelector(selectProductIds);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    await saveProductOrder({
      coffeeMenuId: menuId,
      ids: {
        productIds,
      },
    }).unwrap();

    setEditProducts(false);
  };

  const [editCoffeeMachineProductById, { isError: isSavingError, isSuccess }] =
    useEditCoffeeMenuProductMutation();

  const [
    saveProductOrder,
    {
      isSuccess: isProductOrderSaveSuccess,
      isError: isErrorSaveOrder,
      isLoading: isSaving,
    },
  ] = useSaveProductOrderMutation();

  const handleActiveChange =
    (product: Product) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      await editCoffeeMachineProductById({
        ...product,
        active: event.target.checked,
      }).unwrap();
    };

  const { data: productsState, isLoading } =
    useGetCoffeeMenuProductsQuery(menuId);

  let products: Product[] = [];

  if (productsState) {
    products = productIds.map((id) => {
      return productsState.entities[id]!;
    });
  }

  const { edit: editProduct } = useEntityDialog({
    name: EDIT_PRODUCT_DIALOG_NAME,
  });

  const { open: openModal } = useEntityDialog({
    name: CREATE_PRODUCT_DIALOG_NAME,
  });

  const { data: menu } = useGetCoffeeMenuQuery(menuId);

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        ></Stack>

        <TemporaryAlert open={isSavingError} severity="error">
          {t("product.saveFailure")}
        </TemporaryAlert>

        <TemporaryAlert open={isSuccess}>
          {t("product.saveSuccess")}
        </TemporaryAlert>

        <TemporaryAlert open={isErrorSaveOrder} severity="error">
          {t("products.edit.saveFailure")}
        </TemporaryAlert>

        <TemporaryAlert open={isProductOrderSaveSuccess}>
          {t("products.edit.saveSuccess")}
        </TemporaryAlert>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
          }}
        >
          {!isEditProducts && (
            <Button
              sx={{ width: { sm: "auto" } }}
              startIcon={<AddCircle />}
              onClick={openModal}
            >
              {t("product.addNewProductButton")}
            </Button>
          )}

          {!isEditProducts && (
            <Tooltip
              arrow
              PopperProps={{
                sx: { lineHeight: "1.1rem", pointerEvents: "none" },
              }}
              title={<span>{t(`products.tooltip.editOrder`)}</span>}
            >
              <Link
                href={""}
                sx={{
                  marginLeft: { xs: "auto", sm: 4 },
                  display: "inline-flex",
                  alignItems: "center",
                  height: "100%",
                }}
                onClick={handleEditProducts}
              >
                <EditIcon sx={{ marginRight: 1 }} />
                <u>{t("products.edit.link")}</u>
              </Link>
            </Tooltip>
          )}
        </Box>

        <Grid item xs sx={{ textAlign: { md: "right" } }}>
          {isEditProducts && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Button
                sx={{ px: 13, mb: { xs: 4, sm: 0 }, width: { sm: "auto" } }}
                fullWidth
                startIcon={<ChevronLeft />}
                onClick={closeEditMode}
              >
                {t("back")}
              </Button>

              <Button
                variant="contained"
                sx={{ px: 13, ml: { xs: 0, sm: 4 }, width: { sm: "auto" } }}
                startIcon={!isSaving && <Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            </Box>
          )}
        </Grid>

        <EditProductDialog />
        <CreateProductDialog />

        <TileGrid
          item
          busy={isLoading}
          sortable={isEditProducts}
          sort={(ids) => {
            const newIds = ids as string[];
            dispatch(setProductIds([...newIds]));
          }}
          ids={productIds}
        >
          {products?.map((product: Product) => (
            <ProductTile
              id={product.id}
              key={product.id}
              editMode={isEditProducts}
              onClick={() => {
                if (!isEditProducts) editProduct(product.id)()
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={product.active}
                    onChange={handleActiveChange(product)}
                    onClick={(event: React.SyntheticEvent) => {
                      event.stopPropagation();
                    }}
                  />
                }
                label={capitalize(t("enabled"))}
              />
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <ProductImage
                  src={`${process.env.REACT_APP_API_BASE_URL ?? ""}${
                    product.picture
                  }`}
                  sx={{ opacity: !product.active ? 0.3 : 1 }}
                />
                <Typography sx={{ mt: 2, wordWrap: "break-word" }} fontWeight={"bold"}>
                  {ellipsisOverflow(productName(mt, product), 25)}
                </Typography>

                {typeof product.price === "number" && menu?.mode !== Mode.Open && (
                  <Typography sx={{ mt: 2 }} fontWeight={"bold"}>
                    {product.price === 0
                      ? capitalize(t("free"))
                      : formatMoney(product.price, menu?.company?.currency ?? "CHF")}
                  </Typography>
                )}
              </Box>
            </ProductTile>
          ))}
        </TileGrid>
      </Grid>
    </>
  );
};

export default ProductListing;
