import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  capitalize,
  Checkbox,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useGetUsersQuery,
  useVerifyUsersMutation,
} from "../../state/services/api";
import { getAll } from "../../utils/api";
import { TemporaryAlert } from "../../components/Alert";
import { Role, User } from "../../state/users";

const emailOrFirstname = (user: User) => user.firstName ?? user.email;

const UserVerification = () => {
  const { t } = useTranslation();

  const { data: usersState } = useGetUsersQuery();
  const users = getAll(usersState);

  const unverifiedUsers = useMemo(
    () => users.filter((user) => !user.verified),
    [users]
  );

  const [selection, setSelection] = useState<string[]>([]);
  const [role, setRole] = useState<Record<string, Role>>({});

  const [verifyUsers, { isLoading, isError, isSuccess }] =
    useVerifyUsersMutation();

  const getRole = useCallback(
    (id: string) =>
      role[id] ??
      users.find(({ id: userId }) => userId === id)?.role ??
      Role.Consumer,
    [role, users]
  );

  const verifySelectedUsers = useCallback(async () => {
    const verifications = selection.map((id) => ({
      id,
      role: getRole(id),
    }));
    setSelection([]);
    await verifyUsers(verifications).unwrap();
  }, [getRole, selection, verifyUsers]);

  const handleToggle = (value: string) => () => {
    setSelection((oldSelection) => {
      const currentIndex = oldSelection.indexOf(value);
      const newChecked = [...oldSelection];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      return newChecked;
    });
  };

  const onSelectRole = useCallback((user: User, role: Role) => {
    setRole((roles) => ({ ...roles, [user.id]: role }));
  }, []);

  const roleOptions = Object.values(Role)
    .filter((role) => role !== Role.CompanyOwner)
    .map((value) => ({
      value,
      label: t(`roles.${value}`),
    }));

  if (!unverifiedUsers.length) {
    return (
      <Grid item xs>
        <TemporaryAlert open={!isLoading && isError} severity="error">
          {t("usermanagement.verification.saveFailure")}
        </TemporaryAlert>

        <TemporaryAlert open={!isLoading && isSuccess}>
          {t("usermanagement.verification.saveSuccess")}
        </TemporaryAlert>

        <Paper sx={{ p: 5, mt: 5, width: "fit-content" }}>
          <Typography variant="h3">
            {t("usermanagement.verification.allDone")}
          </Typography>
        </Paper>
      </Grid>
    );
  }

  return (
    <Grid item xs>
      <Stack direction="column">
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            mt: 5,
            bgcolor: "background.paper",
          }}
        >
          {unverifiedUsers.map((user) => {
            const labelId = `checkbox-list-label-${user.id}`;

            return (
              <ListItem
                key={user.id}
                secondaryAction={
                  <Select
                    name="role"
                    title={capitalize(t("role"))}
                    aria-label="role"
                    value={getRole(user.id)}
                    sx={{ m: 0 }}
                    onChange={(event: SelectChangeEvent) =>
                      onSelectRole(user, event.target.value as Role)
                    }
                  >
                    {roleOptions.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        <Tooltip
                          arrow
                          PopperProps={{
                            sx: {
                              lineHeight: "1.1rem",
                              pointerEvents: "none",
                            },
                          }}
                          title={
                            <span>{t(`roles.tooltip.${option.value}`)}</span>
                          }
                        >
                          <Box>{option.label}</Box>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </Select>
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(user.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selection.indexOf(user.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${emailOrFirstname(user)} ${user.lastName}`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        <TemporaryAlert open={!isLoading && isError} severity="error">
          {t("usermanagement.verification.saveFailure")}
        </TemporaryAlert>

        <TemporaryAlert open={!isLoading && isSuccess}>
          {t("usermanagement.verification.saveSuccess")}
        </TemporaryAlert>

        <Button
          variant="contained"
          onClick={verifySelectedUsers}
          disabled={!selection.length}
          sx={{ width: "fit-content", mt: 4 }}
        >
          {isLoading ? (
            <CircularProgress size={20} color="white" />
          ) : (
            t("usermanagement.verification.verifyButtonText")
          )}
        </Button>
      </Stack>
    </Grid>
  );
};

export default UserVerification;
