import {
  Button,
  CircularProgress,
  styled,
  ButtonProps,
  capitalize,
  Box,
} from "@mui/material";
import BaseEntityDialog, {
  EntityDialogProps,
} from "../components/EntityDialog";
import { useTranslation } from "react-i18next";

import {
  EntitiesSelector,
  useEntitiesDialog,
  useEntityDialog,
} from "../hooks/dialog";
import { DeleteForever } from "@mui/icons-material";
import { useCallback } from "react";
import { RootState } from "../state/store";
import { useAlert } from "./alerts/AlertContext";

export interface DeleteDialogProps<T extends { id: string }>
  extends Omit<
    EntityDialogProps,
    "danger" | "okButton" | "title" | "children"
  > {
  title?: string;
  entityName?: string;
  busy: boolean;
  buttonProps?: ButtonProps;
  selector?: (id: string) => (state: RootState) => T | undefined;
  displayName?: ((entity: T) => string) | string;
  onDelete?: (id: string) => void | Promise<unknown>;
  onDeleteMany?: (id: string[]) => void | Promise<unknown>;
  onClose?: () => void;
  errorMessage?: string | ((error: any) => string);
  children?: React.ReactNode;
  disabled?: boolean;
}

const EntityDialog = styled(BaseEntityDialog, {
  shouldForwardProp: (prop) => prop !== "danger",
})<{ danger?: boolean }>`
  .MuiTypography-root {
    ${({ danger, theme }) =>
      danger ? `color: ${theme.palette.error.main}` : undefined}
  }
  .MuiTypography-h2::after,
  .MuiButton-contained {
    ${({ danger, theme }) =>
      danger ? `background-color: ${theme.palette.error.main}` : undefined}
  }
  .MuiButton-contained[disabled] {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .content .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const DeleteDialog = <T extends { id: string; name?: string }>({
  name,
  title,
  buttonProps,
  entityName,
  onDelete,
  onDeleteMany,
  onClose,
  busy,
  displayName = (entity: { name?: string }) => entity.name || "",
  selector,
  children,
  errorMessage,
  disabled = false,
  ...props
}: DeleteDialogProps<T>) => {
  const { t } = useTranslation();

  const { entityId, entity, close } = useEntityDialog<T>({
    name,
    selector,
  });

  const { entityIds } = useEntitiesDialog<T>({
    name,
    selector: selector
      ? (((ids) => (state) =>
          ids.map((id) => selector(id)(state))) as EntitiesSelector<T>)
      : undefined,
  });

  const alert = useAlert();

  const entityDisplayName =
    typeof displayName === "function"
      ? entity && displayName(entity)
      : displayName;

  const deleteEntity = useCallback(async () => {
    try {
      if (!entityId && !entityIds.length) return;
      if (entityId) {
        await onDelete?.(entityId);
      }
      else if (entityIds) {
        await onDeleteMany?.(entityIds);
      }
    } catch (e) {
      let errorMsg =
        typeof errorMessage === "function" ? errorMessage(e) : errorMessage;

      if (!errorMsg) {
        errorMsg = t("deleteError", {
          name: entityDisplayName,
          entityName: t(entityName),
        });
      }

      alert({
        severity: "error",
        content: errorMsg,
      });
    }

    close();
    onClose?.();
  }, [
    close,
    onClose,
    entityId,
    entityIds,
    onDelete,
    onDeleteMany,
    errorMessage,
    alert,
    t,
    entityDisplayName,
    entityName,
  ]);

  if (!title) {
    title = ((entity && entityDisplayName) || !entityId)
      ? t(entityId ? "deletePrompt" : "deletePromptNoName", {
          name: entityDisplayName,
          entityName: t(entityName),
          what: t(entityName)
        })
      : "";
  }

  return (
    <>
      <EntityDialog
        name={name}
        danger
        title={title || ""}
        onClose={onClose}
        okButton={
          <Button
            disabled={disabled}
            variant="contained"
            startIcon={
              busy ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <DeleteForever />
              )
            }
            onClick={deleteEntity}
            color="error"
            {...buttonProps}
          >
            {capitalize(t("remove"))}
          </Button>
        }
        {...props}
      >
        <Box className="content">{children}</Box>
      </EntityDialog>
    </>
  );
};

export default DeleteDialog;
