import {
  useMediaQuery,
  capitalize,
  Typography,
  Grid,
  Link,
  Stack,
} from "@mui/material";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as BaseLink, useParams } from "react-router-dom";
import Amount from "../../../components/Amount";
import { DataGrid } from "../../../components/DataGrid";
import {
  CoffeeMachine,
  selectFilter,
  setFilter,
} from "../../../state/coffe-machines";
import {
  useGetCoffeeMachinesQuery,
  useGetCoffeeMenuQuery,
} from "../../../state/services/api";
import { useSelector } from "react-redux";
import { getAll } from "../../../utils/api";
import muiTheme from "../../../theme";
import CoffeeMachineTreeSelect from "../../../components/CoffeeMachineTreeSelect";

interface MatchParams {
  menuId: string;
}

const CoffeeMachines = () => {
  const { t } = useTranslation();

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const { menuId } = useParams<MatchParams>();

  const { data: menu } = useGetCoffeeMenuQuery(menuId);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "owner",
        valueGetter: (params: GridRenderCellParams<CoffeeMachine>) =>
          params.row.createdByUser.email,
        cellClassName: "owner",
        headerName: capitalize(t("coffeeMachines.property.owner")),
        minWidth: 100,
        flex: 1,
      },
      {
        field: "model",
        cellClassName: "model",
        headerName: capitalize(t("coffeeMachines.property.model")),
        minWidth: 130,
        flex: 1,
      },
      {
        field: "nameHuman",
        cellClassName: "name",
        headerName: capitalize(t("coffeeMachines.property.name")),
        minWidth: smScreen ? 130 : 0,
        flex: 1,
      },
      {
        field: "serial",
        cellClassName: "serial",
        headerName: capitalize(t("coffeeMachines.property.serial")),
        minWidth: 130,
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "location",
        valueGetter: (params) => params.row.location.name,
        cellClassName: "location",
        headerName: capitalize(t("location")),
        minWidth: 130,
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "detail",
        cellClassName: "details",
        headerName: capitalize(t("details")),
        minWidth: smScreen ? 160 : 0,
        headerAlign: "right",
        align: "right",
        flex: 1,
        renderCell: (params: GridRenderCellParams<void>) => {
          return (
            <Link component={BaseLink} to={`/coffee-machines/${params.row.id}`}>
              <Typography
                fontWeight="bold"
                sx={{ textDecoration: "underline" }}
              >
                {capitalize(t("show"))}
              </Typography>
            </Link>
          );
        },
      },
    ],
    [t, smScreen]
  );

  const visibleColumns = useMemo(
    () => ({
      owner: true,
      model: smScreen,
      name: true,
      serial: smScreen,
      location: smScreen,
      detail: true,
    }),
    [smScreen]
  );

  const baseFilter = useSelector(selectFilter);

  const filter = useMemo(
    () =>
      !menu
        ? baseFilter
        : {
            ...baseFilter,
            coffeeMenus: [menu?.id],
          },
    [baseFilter, menu]
  );

  const { data: machinesState } = useGetCoffeeMachinesQuery(filter, {
    skip: !menu,
  });
  const machines = machinesState && getAll(machinesState);

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="flex-end">
        <CoffeeMachineTreeSelect
          selectFilter={selectFilter}
          setFilter={setFilter}
          inputProps={{ sx: { width: "100%" } }}
        />
      </Stack>
      <DataGrid
        head={
          <Amount
            amount={machines?.length ?? 0}
            text={t("coffeeMenu.numberOfMachines", {
              coffeeMenu: menu?.name ?? t("coffeeMenu.thisMenu"),
            })}
          />
        }
        rows={machines ?? []}
        columns={columns}
        pageSize={10}
        headerHeight={32 * 1.5}
        initialState={{
          columns: {
            columnVisibilityModel: visibleColumns,
          },
        }}
      />
    </Grid>
  );
};

export default CoffeeMachines;
