import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import TextInput from "../../components/form/TextInput";
import { Save } from "@mui/icons-material";
import { TemporaryAlert } from "../../components/Alert";
import React, { useCallback } from "react";
import {
  useCreateCompanyMutation,
  useGetCurrenciesQuery,
} from "../../state/services/api";
import * as Yup from "yup";
import MultiSelect from "../../components/form/MultiSelect";
import { TFunction } from "i18next";
import { regionOptions } from "../../state/company/company";

const CompanySchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t("required", { field: "name" })),
    currency: Yup.string().required(),
    region: Yup.string().oneOf(regionOptions(t).map((option) => option.value)),
    defaultLocationName: Yup.string().required(
      t("required", { field: "location" })
    ),
  });

const createCurrencyOptions = (currencies: string[]) =>
  currencies.map((currency) => ({ label: currency, value: currency }));

const CreateCompany = () => {
  const { t } = useTranslation();

  const { data: currencies, isSuccess: currenciesLoaded } =
    useGetCurrenciesQuery();

  const [createCompany, { isLoading: isSaving, isError, isSuccess }] =
    useCreateCompanyMutation();

  const submit = useCallback(
    async (values: typeof initialValues) => {
      await createCompany({
        ...values,
        region: values.region || null
      }).unwrap();
    },
    [createCompany]
  );

  const initialValues = {
    name: "",
    currency: "CHF",
    region: "other",
    defaultLocationName: "",
  };

  if (!currenciesLoaded) {
    return <CircularProgress size={20} color="white" />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="column"
          flexDirection={{ md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h1">
              {t("setup.company.create.title")}
            </Typography>
            <Typography>{t("setup.company.create.subTitle")}</Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item>
        <Formik
          initialValues={initialValues}
          validationSchema={CompanySchema(t)}
          enableReinitialize={true}
          onSubmit={submit}
        >
          <Form>
            <TextInput
              name="name"
              type="text"
              title={capitalize(t("name")) + ":"}
            />
            <TextInput
              name="defaultLocationName"
              title={capitalize(t("location")) + ":"}
              type="text"
            />
            <MultiSelect
              name="currency"
              title={capitalize(t("currency")) + ":"}
              options={createCurrencyOptions(currencies!)}
              helperText={t("required", { field: "currency" })}
              required
            />
            <MultiSelect
              name="region"
              title={capitalize(t("region")) + ":"}
              options={regionOptions(t)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              startIcon={<Save />}
              sx={{ mt: 2 }}
            >
              {isSaving ? (
                <CircularProgress size={20} color="white" />
              ) : (
                t("save")
              )}
            </Button>

            <TemporaryAlert open={isError} severity="error">
              {t("setup.company.saveFailure")}
            </TemporaryAlert>
            <TemporaryAlert open={isSuccess}>
              {t("setup.company.saveSuccess")}
            </TemporaryAlert>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
};

export default CreateCompany;
