import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
  Button,
  useMediaQuery,
  Chip,
  capitalize,
  useTheme,
} from "@mui/material";

import TileGrid from "../../components/TileGrid";

import { DataGrid, GridColumns, GridSortModel } from "@mui/x-data-grid";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  PieLabel,
  Legend,
} from "recharts";
import { useState, useMemo, useEffect } from "react";
import CoffeeMachineTreeSelect from "../../components/CoffeeMachineTreeSelect";

import { selectFilter, setFilter } from "../../state/statistics";
import {
  useGetCoffeeMachinesQuery,
  useGetStatisticQuery,
} from "../../state/services/api";

import { useSelector } from "react-redux";
import counterWidgetImage from "../../assets/widget_icon.png";
import revenueWidgetImage from "../../assets/widget_revenue_icon.png";
import Tile from "../../components/Tile";
import WidgetBody from "../../components/WidgetBody";
import { Metric, Widget } from "../../state/widgets/widgetTypes";
import { useTranslation } from "react-i18next";
import muiTheme from "../../theme";
import { GetApp } from "@mui/icons-material";
import { useStatisticsExport } from "../../hooks/statisticsExport";
import { getAll } from "../../utils/api";
import { ExportType } from "../../hooks/export";

const CounterTile = styled(Tile)`
  .MuiPaper-root {
    background-image: url(${counterWidgetImage});
    background-repeat: no-repeat;
    background-position: bottom;
    background-origin: content-box;
  }
`;

const RevenueTile = styled(Tile)`
  .MuiPaper-root {
    background-image: url(${revenueWidgetImage});
    background-repeat: no-repeat;
    background-position: bottom;
    background-origin: content-box;
  }
`;

const BaseDataGrid = styled(DataGrid)`
  border: 0;
  padding: 0;
  font-size: 0.875rem;

  .MuiDataGrid-cell {
    &.prominent {
      font-weight: bold;
      font-size: 0.875rem;
    }
  }

  .MuiDataGrid-columnHeader {
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0;
    }

    .MuiDataGrid-columnSeparator {
      display: none;
    }
  }
`;

const StatisticWrapper = styled(Box)`
  padding: 4rem;
  width: 595pt;
  @media print {
    .pagebreak {
      page-break-before: always;
    } /* page-break-after works, as well */
  }
`;

const Widgets = () => {
  const filter = useSelector(selectFilter);

  const { data: statistics } = useGetStatisticQuery(filter);

  const widgets = statistics?.widgets || [];

  const widgetElements = widgets?.map((widget: Widget, index: number) => {
    const MyTile = widget.metric === Metric.Revenue ? RevenueTile : CounterTile;

    return (
      <MyTile title={widget.title} id={widget.id} key={widget.id ?? index}>
        <WidgetBody widget={widget} />
      </MyTile>
    );
  });

  return <TileGrid item>{widgetElements}</TileGrid>;
};

const ProductWidgets = () => {
  const filter = useSelector(selectFilter);

  const exportPdf = useStatisticsExport(ExportType.PDF, {
    filter,
    Component: StatisticExport,
    componentProps: { filter },
  });
  const exportCsv = useStatisticsExport(ExportType.CSV, { filter });
  const exportXlsx = useStatisticsExport(ExportType.XLSX, { filter });

  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <>
              <Box>
                <Typography variant="h1">{t("products.title")}</Typography>
                <Typography paragraph>{t("products.subTitle")}</Typography>
              </Box>

              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                rowGap={4}
                sx={{ width: "100%" }}
              >
                <Stack direction="column" width="fit-content" rowGap={2}>
                  <Button
                    sx={{ px: 6, mt: { xs: 2, md: 0 } }}
                    onClick={exportPdf}
                    startIcon={<GetApp />}
                  >
                    {capitalize(t("products.export.pdf"))}
                  </Button>

                  <Button
                    sx={{ px: 6, mb: { xs: 2, md: 0 } }}
                    onClick={exportCsv}
                    startIcon={<GetApp />}
                  >
                    {capitalize(t("products.export.csv"))}
                  </Button>

                  <Button
                    sx={{ px: 6, mb: { xs: 2, md: 0 } }}
                    onClick={exportXlsx}
                    startIcon={<GetApp />}
                  >
                    {capitalize(t("products.export.xlsx"))}
                  </Button>
                </Stack>
              </Stack>
            </>
          </Stack>

          <Stack direction="row">
            <CoffeeMachineTreeSelect
              setFilter={setFilter}
              selectFilter={selectFilter}
            />
          </Stack>
        </Grid>
        <Widgets />
      </Grid>
    </>
  );
};

// https://github.com/recharts/recharts/issues/490#issuecomment-1356721987
const renderCustomizedLabel: PieLabel<any> = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  name,
  fontSize,
  index,
  fill,
}) => {
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(RADIAN * midAngle);
  const cos = Math.cos(RADIAN * midAngle);
  const startX = cx + outerRadius * cos;
  const startY = cy + outerRadius * -sin;
  const middleY = cy + (outerRadius + 20 * Math.abs(sin)) * -sin;
  let endX = startX + (cos >= 0 ? 1 : -1) * 50;
  let textAnchor = cos >= 0 ? "start" : "end";
  const mirrorNeeded =
    midAngle > -270 && midAngle < -210 && percent < 0.04 && index % 2 === 1;
  if (mirrorNeeded) {
    endX = startX + outerRadius * -cos * 2 + 100;
    textAnchor = "start";
  }

  return (
    <g>
      <path
        d={`M${startX},${startY}L${startX},${middleY}L${endX},${middleY}`}
        fill="none"
        stroke={fill}
        strokeWidth={1}
      />
      <text
        x={endX + (cos >= 0 || mirrorNeeded ? 1 : -1) * 12}
        y={middleY + fontSize / 2}
        textAnchor={textAnchor}
        fontSize={fontSize}
        fill={fill}
      >{`${name || ""} ${(percent * 100).toFixed(2)}%`}</text>
    </g>
  );
};

const ProductChart = () => {
  const filter = useSelector(selectFilter);

  const { data: statistics } = useGetStatisticQuery(filter);

  const { t: mt } = useTranslation("machine");

  const rows = statistics?.orderStatistics || [];
  const nonZeroRows = rows
    .filter((val) => {
      return val.percentOfTotal > 0;
    })
    .map((row) => ({
      ...row,
      name: mt(row.name),
    }));

  // https://stackoverflow.com/questions/236936/how-to-pick-color-palette-for-a-pie-chart
  const COLORS = [
    "#56E289",
    "#68E256",
    "#AEE256",
    "#E2CF56",
    "#E28956",
    "#E25668",
    "#E256AE",
    "#CF56E2",
    "#8A56E2",
    "#5668E2",
    "#56AEE2",
    "#56E2CF",
  ];

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <ResponsiveContainer minHeight={550}>
      <PieChart>
        {!isDesktop && <Legend verticalAlign="top" height={36} />}
        <Pie
          dataKey="amountOrdered"
          nameKey="name"
          data={nonZeroRows}
          cx="50%"
          cy="50%"
          width="100%"
          label={isDesktop ? renderCustomizedLabel : undefined}
          labelLine={false}
          startAngle={90}
          endAngle={-270}
          outerRadius="60%"
          innerRadius="50%"
          fill="#8884d8"
          fontSize={12}
          isAnimationActive={false}
        >
          {nonZeroRows?.map((_: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[COLORS.length - 1 - index]}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(_, __, item) => [
            (Math.round(item.payload.percentOfTotal * 100) / 100).toString() +
              "%",
            capitalize(item.payload.name as string),
          ]}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const ProductGrid = ({
  export: exportEnabled = false,
}: {
  export?: boolean;
}) => {
  const filter = useSelector(selectFilter);

  const { data: statistics, refetch } = useGetStatisticQuery(filter);

  const { t } = useTranslation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  let rows = statistics?.orderStatistics || [];

  const { t: mt } = useTranslation("machine");

  rows = rows.map((r) => {
    return {
      ...r,
      name: mt(r.name),
    };
  });

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("products.headers.productName"),
        minWidth: smScreen ? 130 : 0,
        flex: 1,
        cellClassName: "prominent",
      },
      /* {
        field: "successRate",
        headerName: t("products.headers.successRate"),
        minWidth: 80,
        headerAlign: "center",
        align: "center",
        flex: 1,
      }, */
      {
        field: "amountOrdered",
        headerName: t("products.headers.amountOrdered"),
        minWidth: 80,
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "percentOfTotal",
        headerName: t("products.headers.percentOfTotal"),
        minWidth: 80,
        headerAlign: "right",
        align: "right",
        flex: 1,
      },
    ],
    [smScreen, t]
  );

  const visibleColumns = useMemo(
    () => ({
      productCategory: true,
      successRate: smScreen,
      amountOrdered: smScreen,
      percentOfTotal: true,
    }),
    [smScreen]
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "amountOrdered",
      sort: "desc",
    },
  ]);

  return (
    <BaseDataGrid
      rows={rows}
      columns={columns}
      pageSize={10}
      headerHeight={20}
      autoHeight
      columnVisibilityModel={visibleColumns}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      hideFooterPagination={exportEnabled}
      disableColumnMenu
      sx={{
        ".MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer":
          {
            flexDirection: "row",
            justifyContent: "flex-end",
          },
        ".MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer":
          {
            flexDirection: "row",
            justifyContent: "flex-end",
          },
      }}
    />
  );
};

const ProductUsage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <Typography variant="h2">
                {t("product.statisticTitle")}
              </Typography>
            </Box>
            <Divider />
            <Stack
              mt={2}
              p={2}
              rowGap={4}
              minHeight="40vh"
              display="grid"
              gridTemplateColumns="1fr"
            >
              <ProductGrid />
              <ProductChart />
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const StatisticExport = () => {
  const filter = useSelector(selectFilter);
  const { data } = useGetCoffeeMachinesQuery(filter);
  const machines = getAll(data);

  const { t } = useTranslation();

  return (
    <StatisticWrapper>
      <Box>
        <Typography variant="h1">{t("products.export.title")}</Typography>
        {machines.map((machine) => (
          <Chip
            key={machine.id}
            label={
              machine.name
                ? `${machine.name} (${machine.model})`
                : machine.model
            }
            variant="outlined"
            color="primary"
            sx={{ mr: 2 }}
          />
        ))}
      </Box>
      <Widgets />
      <div className="pagebreak" />
      <Box height="2rem" />
      <ProductGrid export={true} />
      <Box height="300pt">
        <ProductChart />
      </Box>
    </StatisticWrapper>
  );
};

const Statistic = () => {
  return (
    <>
      <ProductWidgets />
      <ProductUsage />
    </>
  );
};

export default Statistic;
