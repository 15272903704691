export enum PaymentType {
  Stripe = "stripe",
  Alipay = "alipay",
  Globalpay = "globalpay",
  Saferpay = "saferpay",
  Klarna = "klarna",
}

export interface StripePaymentOptions {
  apiKey?: string;
  clientKey?: string;
  webhookSecret?: string;
}

export interface AlipayPaymentOptions {
  appId?: string;
  gatewayUrl?: string;
  privateKey?: string;
  publicKey?: string;
  alipayPublicKey?: string;
}

export interface GlobalpayPaymentOptions {
  gatewayUrl?: string;
  privateKey?: string;
  publicKey?: string;
  privateKeyPassword?: string;
  merchantNumber?: string;
}

export interface SaferpayPaymentOptions {
  terminalId?: string;
  customerId?: string;
  gatewayUrl?: string;
  username: string;
  password?: string;
}

export interface KlarnaPaymentOptions {
  username?: string;
  password?: string;
  gatewayUrl?: string;
}

export interface StripePayment {
  id: string;
  name: string;
  type: PaymentType.Stripe;
  options: StripePaymentOptions;
  createdAt: Date;
}

export interface AlipayPayment {
  id: string;
  name: string;
  type: PaymentType.Alipay;
  options: AlipayPaymentOptions;
  createdAt: Date;
}

export interface GlobalpayPayment {
  id: string;
  name: string;
  type: PaymentType.Globalpay;
  options: GlobalpayPaymentOptions;
  createdAt: Date;
}

export interface SaferpayPayment {
  id: string;
  name: string;
  type: PaymentType.Saferpay;
  options: SaferpayPaymentOptions;
  createdAt: Date;
}

export interface KlarnaPayment {
  id: string;
  name: string;
  type: PaymentType.Klarna;
  options: KlarnaPaymentOptions;
  createdAt: Date;
}

export type Payment =
  | StripePayment
  | AlipayPayment
  | GlobalpayPayment
  | SaferpayPayment
  | KlarnaPayment;

