import {
  capitalize,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { Option } from "../../utils/types";
import { FieldHookConfig, useField } from "formik";
import BaseMultiSelect, { SelectProps } from "../MultiSelect";

export type FormSelectProps<
  T extends Option,
  M extends boolean = false
> = SelectProps<T, M> & {
  title?: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  fieldConfig?: FieldHookConfig<T["value"][] | T["value"]>;
};

const MultiSelect = <T extends Option, M extends boolean>({
  fieldConfig,
  title,
  helperText,
  name,
  children,
  onChange,
  formControlProps,
  ...props
}: FormSelectProps<T, M>) => {
  const [field, meta, { setValue }] = useField({
    ...fieldConfig,
    type: "checkbox",
    multiple: props.multiple,
    name,
  });

  const showError = meta.touched && !!meta.error;

  const actualOnChange: SelectProps<T, M>["onChange"] = (v) => {
    onChange?.(v);
    setValue(v);
  };

  return (
    <FormControl margin="normal" fullWidth {...formControlProps}>
      <InputLabel shrink id={`${name}-label`} htmlFor={name}>
        {title}
      </InputLabel>
      <BaseMultiSelect
        error={showError}
        onBlur={field.onBlur}
        name={name}
        value={field.value}
        onChange={actualOnChange}
        {...props}
      >
        {children}
      </BaseMultiSelect>
      {showError && (
        <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
          {capitalize((showError ? meta.error : helperText) || "")}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MultiSelect;
