import PropTypes from "prop-types";
import List from "@mui/material/List";
import MuiDrawer from "@mui/material/Drawer";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useTheme,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  Dashboard,
  Settings,
  People,
  ExitToApp,
  MenuBook,
  Help,
} from "@mui/icons-material";
import { ReactComponent as RawCoffeeMachineIcon } from "../assets/svg/coffee_machine.svg";
import { Role } from "../state/users";
import { useAuthorized } from "../hooks/auth";
import {
  routeRoles,
  selectCurrentUser,
  selectLogoutDialogOpen,
  setLogoutDialogOpen,
} from "../state/auth";
import { useDispatch, useSelector } from "../state/hooks";
import { useCallback } from "react";

interface DrawerProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

const CoffeeMachineIcon = styled(RawCoffeeMachineIcon)`
  path {
    fill: currentColor;
  }
  rect {
    fill: currentColor;
  }
`;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})`
  &,
  & .MuiDrawer-paper {
    white-space: nowrap;
    overflow-x: hidden;
    padding-top: 2rem;
    transition: ${({ theme }) =>
      theme.transitions.create("width", {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      })};
    width: var(--drawer-width);
    transition: ${({ theme }) =>
      theme.transitions.create("width", {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      })};

    ${(props) => props.theme.breakpoints.down("sm")} {
      padding: ${({ theme }) => theme.spacing(4)};
      padding-top: ${({ theme }) => theme.spacing(2)};
    }
  }

  @media print {
    display: none;
  }
`;

const ListItemIcon = styled(MuiListItemIcon, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "active",
})<{ open?: boolean; active?: boolean }>`
  ${({ theme }) => `
    position: relative;
    transition: ${theme.transitions.create("left", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    })};
    `}
  ${({ open, theme }) =>
    open
      ? `
      left: 0;
      `
      : `
      left: ${theme.spacing(0.5)};
      `}
`;

const ListItem = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "active",
})<{ open?: boolean; active?: boolean }>`
  ${({ open, theme }) =>
    `
    flex-flow: row;
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    white-space: nowrap;
    
    .item-text {
      transition: ${theme.transitions.create("opacity", {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      })};
      ${
        open
          ? `
      opacity: 1;
      `
          : `
      opacity: 0;
      `
      }
    }
`}
  ${({ active, theme }) =>
    active &&
    `
&, &:hover {
  color: ${theme.palette.primary.main};
  text-decoration: underline;
}
`}
`;

interface NavItemProps {
  text?: string;
  to?: string;
  onClick?: () => void;
  exact?: boolean;
  theme: Theme;
  icon: ReturnType<typeof Dashboard>;
  onlyRoles?: Role[] | Role;
  open: boolean;
  active?: boolean;
  className?: string;
}

const NavItem = ({
  theme,
  text,
  icon,
  to,
  onClick,
  exact,
  onlyRoles,
  open,
  active,
  className,
}: NavItemProps) => {
  const authorized = useAuthorized(onlyRoles);
  const linkProps = {
    to,
    exact,
    component: NavLink,
    activeStyle: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  };
  const buttonProps = { onClick, component: "div" };

  const extraProps = to ? linkProps : buttonProps;

  return authorized ? (
    <Tooltip
      arrow
      PopperProps={{
        sx: {
          lineHeight: "1.1rem",
          pointerEvents: "none",
        },
      }}
      title={open ? "" : text /* Only show if closed */}
    >
      <ListItem
        key={text}
        active={active}
        open={open}
        {...extraProps}
        className={className}
      >
        <ListItemIcon open={open} sx={{ color: "currentcolor" }}>
          {icon}
        </ListItemIcon>
        {text && (
          <ListItemText
            sx={{ width: "100%" }}
            className="item-text"
            primary={text}
            primaryTypographyProps={{
              variant: "body1",
              align: "left",
            }}
          />
        )}
      </ListItem>
    </Tooltip>
  ) : null;
};

function MiniDrawer({ open, handleDrawerToggle }: DrawerProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  const user = useSelector(selectCurrentUser);

  const smScreen = useMediaQuery<typeof theme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const dispatch = useDispatch();
  const isExiting = useSelector(selectLogoutDialogOpen);
  const logout = useCallback(
    () => dispatch(setLogoutDialogOpen(true)),
    [dispatch]
  );

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        // For some reason styled doesn't work here.
        // styled doesn't apply the styles to the drawer root element
        // while sx does...
        zIndex: theme.zIndex.appBar - 1,
        textAlign: "right",
        [theme.breakpoints.down("sm")]: {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          transform: open ? "translateX(0)" : "translateX(-100%)",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp,
          }),
        },
      }}
    >
      <Toolbar />
      {!smScreen && (
        <Link to="/profile">
          <Typography fontWeight="bold">{`${user?.firstName} ${user?.lastName}`}</Typography>
        </Link>
      )}
      <List component="nav" sx={{ pt: 2 }}>
        <NavItem
          theme={theme}
          text={t("navigation.items.dashboard")}
          icon={<Dashboard />}
          to="/"
          exact
          onlyRoles={routeRoles.dashboard}
          open={open}
        />
        <NavItem
          theme={theme}
          text={t("navigation.items.coffeeMachines")}
          icon={<CoffeeMachineIcon />}
          to="/coffee-machines"
          className="link-coffee-machines"
          onlyRoles={routeRoles.coffeeMachines}
          open={open}
        />
        <NavItem
          theme={theme}
          text={t("navigation.items.coffeeMenus")}
          icon={<MenuBook />}
          to="/coffee-menus"
          className="link-coffee-menus"
          onlyRoles={routeRoles.coffeeMenus}
          open={open}
        />
        <NavItem
          theme={theme}
          text={t("navigation.items.setup")}
          icon={<Settings />}
          to="/setup"
          className="link-setup"
          onlyRoles={routeRoles.setup}
          open={open}
        />
        <NavItem
          theme={theme}
          text={t("navigation.items.userManagement")}
          icon={<People />}
          to="/user-management"
          className="link-user-management"
          onlyRoles={routeRoles.userManagement}
          open={open}
        />
        <NavItem
          theme={theme}
          text={t("navigation.items.help")}
          icon={<Help />}
          to="/help"
          className="link-help"
          open={open}
        />
        <NavItem
          theme={theme}
          text={t("navigation.items.exit")}
          icon={<ExitToApp />}
          onClick={logout}
          active={isExiting}
          open={open}
        />
      </List>
      {smScreen && (
        <List component="nav" sx={{ mt: "auto" }}>
          <NavItem
            theme={theme}
            text={open ? t("navigation.items.closeButton") : undefined}
            icon={<MenuOpen />}
            onClick={handleDrawerToggle}
            open={open}
          />
        </List>
      )}
    </Drawer>
  );
}

MiniDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default MiniDrawer;
