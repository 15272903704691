import {
  capitalize,
  FormControl,
  FormControlProps,
  InputLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from "@mui/x-date-pickers";
import { FieldHookConfig, useField } from "formik";
import { useMemo } from "react";
import { useCallback } from "react";

type DateTimePickerProps<T> = Omit<
  MuiDateTimePickerProps<T, Date> & FieldHookConfig<T>,
  "value" | "name" | "children" | "onChange" | "ref" | "renderInput"
> & {
  title: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  textInputProps?: TextFieldProps;
  onChange?: MuiDateTimePickerProps<T, Date>["onChange"];
  name: string;
};

const DateTimePicker = ({
  title,
  helperText,
  formControlProps,
  textInputProps,
  name,
  onChange,
  ...props
}: DateTimePickerProps<Date | null>) => {
  const [field, meta, helpers] = useField({
    ...(textInputProps as Partial<FieldHookConfig<Date | null>>),
    name,
    type: "text",
  });
  const showError = meta.touched && !!meta.error;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoField = useMemo(() => field, Object.values(field));
  const change = useCallback(
    (newValue: Date | null) => {
      helpers.setValue(newValue);
      onChange?.(newValue);
    },
    [onChange, helpers]
  );
  return (
    <FormControl margin="normal" fullWidth {...formControlProps}>
      <InputLabel shrink>{title}</InputLabel>

      <MuiDateTimePicker
        {...memoField}
        {...props}
        onChange={change}
        renderInput={(params: TextFieldProps) => (
          <TextField
            id={name}
            error={showError}
            helperText={capitalize((showError ? meta.error : helperText) || "")}
            type="text"
            {...textInputProps}
            {...params}
            value={params.value || ""}
          />
        )}
      />
    </FormControl>
  );
};

export default DateTimePicker;
