import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";
import { Box, Button, capitalize, Grid, Tooltip } from "@mui/material";
import { Role } from "../../state/users";
import { useEntityDialog } from "../../hooks/dialog";
import NewUserModal, { UserCategory } from "./NewUserModal";
import EditRoleModal from "./EditRoleModal";
import AddCreditModal from "./AddCreditModal";
import { ADD_USER_DIALOG_NAME } from "./modals";
import UserGrid from "./UserGrid";
import EditUserGroupAssignment from "./EditUserGroupAssignmentModal";

const UserManagement = () => {
  const { t } = useTranslation();

  const { open: openModal } = useEntityDialog({
    name: ADD_USER_DIALOG_NAME,
  });

  return (
    <>
      <NewUserModal roles={[Role.Consumer]} category={UserCategory.Consumer} />
      <EditRoleModal />
      <EditUserGroupAssignment />
      <AddCreditModal />

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Tooltip
            arrow
            title={capitalize(t("usermanagement.addNewConsumerButtonTooltip"))}
          >
            <Button
              sx={{
                px: 6,
                py: 1,
                my: { xs: 4, md: 0 },
                width: { sm: "auto" },
              }}
              fullWidth
              startIcon={<PersonAddIcon />}
              onClick={openModal}
              className="add-users"
            >
              {t("usermanagement.addNewConsumerButton")}
            </Button>
          </Tooltip>
        </Box>

        <UserGrid />
      </Grid>
    </>
  );
};

export default UserManagement;
