import { TFunction } from "i18next";
import { ReactElement, ReactFragment } from "react";
import * as Yup from "yup";
import { OptionalObjectSchema, ObjectShape, TypeOfShape } from "yup/lib/object";
import { AnyObject } from "yup/lib/types";
import { Payment, PaymentType } from "..";
import StripeProvider from "./StripeProvider";
import AlipayProvider from "./AlipayProvider";
import GlobalpayProvider from "./GlobalpayProvider";
import KlarnaProvider from "./KlarnaProvider";
import SaferpayProvider from "./SaferpayProvider";
import NoneProvider from "./NoneProvider";
import { KeysOfUnion } from "../../../utils/types";

export interface PaymentProvider {
  type: string;
  options: readonly KeysOfUnion<Payment["options"]>[];
  getOptionSchema(
    schema: OptionalObjectSchema<
      ObjectShape,
      AnyObject,
      TypeOfShape<ObjectShape>
    >,
    t: TFunction
  ): Yup.BaseSchema;
  fields(props: any): ReactElement | ReactFragment | null;
}

export function providerForType(type: PaymentType | "none") {
  switch(type) {
    case PaymentType.Stripe: return new StripeProvider()
    case PaymentType.Alipay: return new AlipayProvider()
    case PaymentType.Globalpay: return new GlobalpayProvider()
    case PaymentType.Saferpay: return new SaferpayProvider()
    case PaymentType.Klarna: return new KlarnaProvider()
    case "none": return new NoneProvider()
  }
}
