import {
  Button,
  OutlinedInputProps,
  styled,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import TreeSelect, { TreeSelectItem, Node } from "../components/TreeSelect";
import {
  selectCompany,
  selectCompanies,
  Company,
  setCompany,
} from "../state/auth";
import { useDispatch, useSelector } from "../state/hooks";
import { ellipsisOverflow } from "../utils/string";
import { useHistory } from "react-router-dom";

const TreeSelectItemLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "disabled",
})<{ selected?: boolean; disabled?: boolean }>`
  width: 100%;
  border-radius: .2rem;
  padding: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(0.5)} 0;
  ${({ disabled }) =>
    disabled &&
    `
    color: #ccc;
  `}
  ${({ selected, theme }) =>
    selected
      ? `
    background: ${theme.palette.primary.main};
    color: ${theme.palette.white.main};
  `
      : `
  &:hover {
    background: ${theme.palette.primary.light};
  }
  `}
`;

type CompanyTreeItem = {
  children?: CompanyTreeItem[];
} & Company;

type CompanyTree = CompanyTreeItem[];

const buildCompanyTree = (companies: Company[]): CompanyTree => {
  const items: Record<string, CompanyTreeItem> = Object.fromEntries(
    companies.map((company) => [company.id, { ...company }])
  );

  for (let [, company] of Object.entries(items)) {
    if (company.parentCompanyId) {
      const item = items[company.parentCompanyId];
      if (!item) continue;
      if (!item.children) item.children = [] as CompanyTreeItem[];
      item.children.push(company);
    }
  }

  return Object.values(items).filter(
    (company) => !company.parentCompanyId || !items[company.parentCompanyId]
  );
};

const CompanySelectItems = ({
  companyTree,
  value,
}: {
  companyTree?: CompanyTree;
  value: string[];
}) => {
  return (
    <>
      {companyTree?.map((company) => (
        <TreeSelectItem
          key={company.id}
          nodeId={company.id}
          renderLabel={
            <TreeSelectItemLabel
              variant="h3"
              selected={value.includes(company.id)}
            >
              {ellipsisOverflow(company.name, 40)}
            </TreeSelectItemLabel>
          }
          hideCheckbox={true}
        >
          {company.children && (
            <CompanySelectItems companyTree={company.children} value={value} />
          )}
        </TreeSelectItem>
      ))}
    </>
  );
};

const CompanySelect = (props: OutlinedInputProps) => {
  const company = useSelector(selectCompany);
  const companies = useSelector(selectCompanies);
  const history = useHistory();

  const tree = buildCompanyTree(companies ?? []);

  const dispatch = useDispatch();

  const getNodeName = useCallback(
    (node: Node) => companies?.find((company) => company.id === node.id)?.name,
    [companies]
  );

  const changeCompany = (ids: string[]) => {
    if (!ids.length) return;
    dispatch(setCompany(ids[0]));
    history.push("/");
  };

  return (
    <TreeSelect
      single={true}
      value={company ? [company.id] : []}
      onChange={changeCompany}
      defaultExpanded={true}
      renderInput={(nodes) => (
        <Button>
          <Typography fontWeight="bold">
            {nodes.length && ellipsisOverflow(getNodeName(nodes[0]), 40)}
          </Typography>
        </Button>
      )}
    >
      {tree && (
        <CompanySelectItems
          companyTree={tree}
          value={company ? [company.id] : []}
        />
      )}
    </TreeSelect>
  );
};

export default CompanySelect;
