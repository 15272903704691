import { createEntityAdapter } from "@reduxjs/toolkit";
import { CoffeeMachine } from "../coffe-machines";
import { User } from "../users";

export enum RecurringTopUpInterval {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum RecurringTopUpInstant {
  Start = 'start',
  End = 'end',
}

export interface UserGroup {
  id: string;
  name: string;
  coffeeMachines: CoffeeMachine[];
  users: User[];
  coffeeMenuId?: string;
  recurringTopUpEnabled: boolean;
  recurringTopUpInstant: RecurringTopUpInterval | null;
  recurringTopUpInterval: RecurringTopUpInterval | null;
  recurringTopUpIntervalAmount: number | null;
}

export const userGroupsAdapter = createEntityAdapter<UserGroup>({
  selectId: (user) => user.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});
