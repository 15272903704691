import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
} from "@mui/x-data-grid";
import { Box, Paper, Stack, styled } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

export const BaseDataGrid = styled(MuiDataGrid)`
  border: 0;
  padding: 1rem;
  font-size: 0.875rem;

  .MuiDataGrid-cell {
    &.prominent {
      font-weight: bold;
      font-size: 0.875rem;
    }
  }

  .MuiDataGrid-columnHeader {
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0;
    }

    .MuiDataGrid-columnSeparator {
      display: none;
    }
  }
`;

export const Head = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.light};
  padding: 1rem;
  padding-bottom: 1.8rem;
`;

interface DataGridProps extends MuiDataGridProps {
  head?: React.ReactNode;
  tableOnly?: boolean;
}

export const DataGrid = forwardRef(
  (
    { head, tableOnly = false, ...props }: DataGridProps,
    ref: ForwardedRef<HTMLDivElement>
  ) =>
    tableOnly ? (
      <BaseDataGrid
        ref={ref}
        autoHeight
        {...props}
        rowsPerPageOptions={[props.pageSize ?? 10]}
      />
    ) : (
      <Paper>
        <Stack direction="column">
          {head && <Head>{head}</Head>}
          <Box flex="1">
            <BaseDataGrid
              ref={ref}
              autoHeight
              {...props}
              rowsPerPageOptions={[props.pageSize ?? 10]}
            />
          </Box>
        </Stack>
      </Paper>
    )
);
