import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEntityDialog } from "../../hooks/dialog";
import React, { useMemo } from "react";
import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { capitalize } from "../../utils/string";
import { Link as BaseLink } from "react-router-dom";
import muiTheme from "../../theme";
import { DataGrid } from "../../components/DataGrid";
import Amount from "../../components/Amount";
import { getAll } from "../../utils/api";
import AddCoffeeMenuDialog, {
  ADD_MENU_DIALOG_NAME,
} from "./AddCoffeeMenuDialog";
import { useGetCoffeeMenusQuery } from "../../state/services/api";

const CoffeeMenuListing = () => {
  const { t } = useTranslation();

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const { open: openAddMenuDialog } = useEntityDialog({
    name: ADD_MENU_DIALOG_NAME,
  });

  const { data: menusState } = useGetCoffeeMenusQuery();
  const coffeeMenus = menusState && getAll(menusState);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        cellClassName: "name",
        headerName: capitalize(t("coffeeMenus.property.name")),
        minWidth: 130,
        flex: 1,
      },
      {
        field: "model",
        cellClassName: "model",
        headerName: capitalize(t("coffeeMenus.property.model")),
        minWidth: smScreen ? 130 : 0,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value} (${params.row.xml})`,
      },
      {
        field: "mode",
        cellClassName: "mode",
        headerName: capitalize(t("coffeeMenus.property.mode")),
        minWidth: smScreen ? 130 : 0,
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          t(`coffeeMenus.modeOptions.${params.value}`),
      },
      {
        field: "detail",
        cellClassName: "details",
        headerName: capitalize(t("details")),
        minWidth: smScreen ? 160 : 0,
        headerAlign: "right",
        align: "right",
        flex: 1,
        renderCell: (params: GridRenderCellParams<void>) => {
          return (
            <Link
              component={BaseLink}
              to={`/coffee-menus/${params.row.id}`}
              className="link-coffee-menu"
            >
              <Typography
                fontWeight="bold"
                sx={{ textDecoration: "underline" }}
              >
                {capitalize(t("show"))}
              </Typography>
            </Link>
          );
        },
      },
    ],
    [t, smScreen]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={4}>
        <Stack
          direction="column"
          flexDirection={{ md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h1">{t("coffeeMenus.title")}</Typography>
            <Typography paragraph sx={{ maxWidth: "600px" }}>{t("coffeeMenus.subTitle")}</Typography>
          </Box>
          <Button
            sx={{
              px: { xs: 0, sm: 10 },
              py: 1,
              width: { xs: "100%", sm: "auto " },
            }}
            startIcon={<AddCircle />}
            onClick={openAddMenuDialog}
          >
            {t("coffeeMenus.addMenuButtonText")}
          </Button>
          <AddCoffeeMenuDialog />
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          head={
            <Amount
              amount={coffeeMenus?.length ?? 0}
              text={t("coffeeMenu.numberOfMenus")}
            />
          }
          rows={coffeeMenus ?? []}
          columns={columns}
          pageSize={10}
          headerHeight={32 * 1.5}
        />
      </Grid>
    </Grid>
  );
};

export default CoffeeMenuListing;
