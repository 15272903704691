import { Grid, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";
import { NavButton } from "../../components/NavButton";
import PageHeading from "../../components/PageHeading";
import Help from "./Help";
import FeedbackForm from "./Feedback";

const HelpSection = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeading />
        <ButtonGroup
          sx={{
            flexFlow: "row wrap",
            "> *": {
              width: "min-content",
              minWidth: "100px!important",
              whiteSpace: "nowrap",
            },
          }}
        >
          <NavButton to="/help/user-guide">
            {t("help.tabs.guide")}
          </NavButton>
          <NavButton
            to="/help/feedback"
          >
            {t("help.tabs.feedback")}
          </NavButton>
        </ButtonGroup>
      </Grid>
      <Switch>
        <Route path="/help/user-guide">
          <Help />
        </Route>
        <Route path="/help/feedback">
          <FeedbackForm />
        </Route>
        <Route path="/help">
          <Redirect from="/help" to="/help/user-guide" />
        </Route>
      </Switch>
    </Grid>
  );
};

export default HelpSection;
