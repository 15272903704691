import { createEntityAdapter } from "@reduxjs/toolkit";
import { CoffeeMachine } from "../coffe-machines";
import { Location } from "../locations";
import { Consumption } from "../products";
import { UserGroup } from "../user-groups";

export interface RegisterUser {
  firstName?: string;
  lastName?: string;
  password?: string;
  email: string;
}

export enum Status {
  Pending = "pending",
  Active = "active",
}

export interface UserFilter {
  ids?: string[];
  userGroupIds?: string[];
  status?: Status;
  companyId?: string;
  fields?: string[];
  minBalance?: number;
  maxBalance?: number;
}

export interface ConsumptionFilter {
  productIds?: string[];
  from?: Date;
  to?: Date;
  menuIds?: string[];
  machineIds?: string[];
  locationIds?: string[];
  userGroupIds?: string[];
  noUserGroup?: boolean;
  noLocation?: boolean;
  companyId?: string;
}

export interface UserConsumptions {
  id: string;
  user: User;
  consumptions: Consumption[];
}

export enum Role {
  Admin = "admin",
  Consumer = "consumer",
  UserManager = "user_manager",
  ProductManager = "product_manager",
  CoffeeMachineOwner = "coffee_machine_owner",
  Reporter = "reporter",
  CompanyOwner = "company_owner",
}

export interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  preferredLanguage?: string;
  password?: string;
  email: string;
  active?: boolean;
  verified?: boolean;
  isKeycloakUser?: boolean;
  role?: Role;
  coffeeMachines?: CoffeeMachine[];
  userGroups?: UserGroup[];
  locations?: Location[];
  lastConnection?: string;
  tutorialCompleted?: boolean;
}

export interface UserState {
  newUserModalOpen: boolean;
  userRoleModalOpen: boolean;
  userModalOpen: boolean;
  selectedUserId?: string;
}

export const usersAdapter = createEntityAdapter<User>({
  selectId: (user) => user.id,
  sortComparer: (a, b) => a.email.localeCompare(b.email),
});
